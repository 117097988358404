html,
body {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
i,
b,
textarea {
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

input{
  color-scheme: dark !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1C2023;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#react-select-listbox,
#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox,
#react-select-5-listbox,
#react-select-6-listbox,
#react-select-7-listbox,
#react-select-8-listbox,
#react-select-9-listbox,
#react-select-10-listbox,
#react-select-11-listbox,
#react-select-12-listbox,
#react-select-13-listbox,
#react-select-14-listbox,
#react-select-15-listbox,
#react-select-16-listbox,
#react-select-17-listbox,
#react-select-18-listbox,
#react-select-19-listbox,
#react-select-20-listbox,
#react-select-21-listbox,
#react-select-22-listbox,
#react-select-23-listbox,
#react-select-24-listbox,
#react-select-25-listbox,
#react-select-26-listbox,
#react-select-27-listbox,
#react-select-28-listbox,
#react-select-29-listbox,
#react-select-30-listbox,
#react-select-31-listbox,
#react-select-32-listbox,
#react-select-33-listbox,
#react-select-34-listbox,
#react-select-35-listbox,
#react-select-36-listbox,
#react-select-37-listbox,
#react-select-38-listbox,
#react-select-39-listbox,
#react-select-40-listbox,
#react-select-41-listbox,
#react-select-42-listbox,
#react-select-43-listbox,
#react-select-44-listbox,
#react-select-45-listbox,
#react-select-46-listbox,
#react-select-47-listbox,
#react-select-48-listbox,
#react-select-49-listbox,
#react-select-50-listbox,
#react-select-51-listbox,
#react-select-52-listbox,
#react-select-53-listbox,
#react-select-54-listbox,
#react-select-55-listbox,
#react-select-56-listbox,
#react-select-57-listbox,
#react-select-58-listbox,
#react-select-59-listbox,
#react-select-60-listbox,
#react-select-61-listbox,
#react-select-62-listbox,
#react-select-63-listbox,
#react-select-64-listbox,
#react-select-65-listbox,
#react-select-66-listbox,
#react-select-67-listbox,
#react-select-68-listbox,
#react-select-69-listbox,
#react-select-70-listbox,
#react-select-71-listbox,
#react-select-72-listbox,
#react-select-73-listbox,
#react-select-74-listbox,
#react-select-75-listbox,
#react-select-76-listbox,
#react-select-77-listbox,
#react-select-78-listbox,
#react-select-79-listbox,
#react-select-80-listbox,
#react-select-81-listbox,
#react-select-82-listbox,
#react-select-83-listbox,
#react-select-84-listbox,
#react-select-85-listbox,
#react-select-86-listbox,
#react-select-87-listbox,
#react-select-88-listbox,
#react-select-89-listbox,
#react-select-90-listbox,
#react-select-91-listbox,
#react-select-92-listbox,
#react-select-93-listbox,
#react-select-94-listbox,
#react-select-95-listbox,
#react-select-96-listbox,
#react-select-97-listbox,
#react-select-98-listbox,
#react-select-99-listbox,
#react-select-100-listbox {
  background-color: rgba(28,32,35);
}